var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var axios = require("axios");
var SERVER_ADDRESS = process.env.NODE_ENV === "production"
    ? "https://dynamo-guesser-server-zpnlf6lmzq-uc.a.run.app"
    : "http://localhost:3000";
// Throws an error if the response is not successful.
// To preserve the images and their order, we send the image number as a parameter.
var ImageServer = /** @class */ (function () {
    function ImageServer() {
    }
    ImageServer.generateImage = function (userToken, sessionCode, imageNumber) {
        return __awaiter(this, void 0, void 0, function () {
            var response;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, axios.get("".concat(SERVER_ADDRESS, "/generate-hint?code=").concat(sessionCode, "&imageNumber=").concat(imageNumber), {
                            headers: { Authorization: "Bearer ".concat(userToken) },
                        })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                }
            });
        });
    };
    return ImageServer;
}());
export { ImageServer };
var ShownSessionEntry = /** @class */ (function () {
    function ShownSessionEntry(sessionCode, creationTimestamp, creator) {
        this.sessionCode = sessionCode;
        this.creationTimestamp = creationTimestamp;
        this.creator = creator;
    }
    return ShownSessionEntry;
}());
export { ShownSessionEntry };
var SessionCacheRemote = /** @class */ (function () {
    function SessionCacheRemote() {
    }
    SessionCacheRemote.registerSession = function (userToken, prompt) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_1, axiosError;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(SERVER_ADDRESS, "/register-session?prompt=").concat(prompt), {
                                headers: { Authorization: "Bearer ".concat(userToken) },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_1 = _a.sent();
                        if (axios.isAxiosError(error_1)) {
                            axiosError = error_1;
                            if (axiosError.response && axiosError.response.status === 429) {
                                throw axiosError;
                            }
                        }
                        console.error(error_1);
                        return [2 /*return*/, ""];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.checkGuess = function (userToken, guess, sessionCode) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(SERVER_ADDRESS, "/check-guess?guess=").concat(guess, "&code=").concat(sessionCode), {
                                headers: { Authorization: "Bearer ".concat(userToken) },
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.status === 200) {
                            return [2 /*return*/, response.data === true];
                        }
                        console.error("Error response code: " + response.status);
                        return [3 /*break*/, 3];
                    case 2:
                        error_2 = _a.sent();
                        console.error(error_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.listActiveSessions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_3;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(SERVER_ADDRESS, "/list-active-sessions"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_3 = _a.sent();
                        console.error(error_3);
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.getDailyChallenge = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(SERVER_ADDRESS, "/daily-challenge"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_4 = _a.sent();
                        console.error(error_4);
                        return [2 /*return*/, ""];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.readyChallengesCount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_5;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.get("".concat(SERVER_ADDRESS, "/ready-challenges-count"))];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_5 = _a.sent();
                        console.error(error_5);
                        return [2 /*return*/, ""];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.registerUser = function (email, password, username) {
        var _a;
        return __awaiter(this, void 0, void 0, function () {
            var response, error_6, readableError;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post("".concat(SERVER_ADDRESS, "/register-user"), {
                                email: email,
                                password: password,
                                username: username,
                            })];
                    case 1:
                        response = _b.sent();
                        return [2 /*return*/, null];
                    case 2:
                        error_6 = _b.sent();
                        if (axios.isAxiosError(error_6) && error_6.response) {
                            readableError = (_a = error_6.response.data) === null || _a === void 0 ? void 0 : _a.error;
                            if (readableError) {
                                return [2 /*return*/, Error(readableError)];
                            }
                        }
                        console.error(error_6);
                        return [2 /*return*/, null];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.addDailyChallenge = function (date, word, overwrite, userToken) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_7;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post("".concat(SERVER_ADDRESS, "/add-daily-challenge?date=").concat(date, "&word=").concat(word, "&overwrite=").concat(overwrite), {}, {
                                headers: { Authorization: "Bearer ".concat(userToken) },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_7 = _a.sent();
                        console.error(error_7);
                        return [2 /*return*/, ""];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.checkUserGuessedSessions = function (sessions, userToken) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_8;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post("".concat(SERVER_ADDRESS, "/check-guessed-sessions"), { sessions: sessions }, {
                                headers: { Authorization: "Bearer ".concat(userToken) },
                            })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_8 = _a.sent();
                        console.error(error_8);
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.getSessionsInfoForUser = function (userToken, sessions) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_9;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post("".concat(SERVER_ADDRESS, "/get-sessions-info"), { sessions: sessions }, {
                                headers: { Authorization: "Bearer ".concat(userToken) },
                            })];
                    case 1:
                        response = _a.sent();
                        if (response.status === 200) {
                            return [2 /*return*/, response.data];
                        }
                        throw new Error("Server responded with status: ".concat(response.status));
                    case 2:
                        error_9 = _a.sent();
                        console.error('Error fetching session info:', error_9);
                        throw error_9;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    SessionCacheRemote.returnLeaderboardStats = function (sessions) {
        return __awaiter(this, void 0, void 0, function () {
            var response, error_10;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, axios.post("".concat(SERVER_ADDRESS, "/get-leaderboard"), { sessions: sessions })];
                    case 1:
                        response = _a.sent();
                        return [2 /*return*/, response.data];
                    case 2:
                        error_10 = _a.sent();
                        console.error(error_10);
                        return [2 /*return*/, []];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return SessionCacheRemote;
}());
export { SessionCacheRemote };
