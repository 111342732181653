var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { WordValidator } from "../word-validator";
import { ImageServer, SessionCacheRemote } from "../backend-api/server-api";
import Cookies from 'js-cookie';
import Fireworks from "@fireworks-js/react";
import "./common-styles.css";
import "./GuessRiddleScreen.css";
var GuessRiddleScreen = function (_a) {
    var userToken = _a.userToken, currentUser = _a.currentUser, logout = _a.logout;
    var sessionCodeParam = useParams().sessionCodeParam;
    var sessionCode = sessionCodeParam;
    var MAX_NUMBER_OF_HINTS = 4;
    var _b = useState([]), images = _b[0], setImages = _b[1];
    var _c = useState(false), loadingNextHint = _c[0], setLoadingNextHint = _c[1];
    var _d = useState(""), playerGuess = _d[0], setPlayerGuess = _d[1];
    var _e = useState(true), isValidSessionCode = _e[0], setIsValidSessionCode = _e[1];
    var _f = useState(""), error = _f[0], setError = _f[1];
    var _g = useState(""), validationResult = _g[0], setValidationResult = _g[1];
    var _h = useState(false), guessedCorrectly = _h[0], setGuessedCorrectly = _h[1];
    useEffect(function () {
        setLoadingNextHint(false);
        buildInitialSessionInfo();
        if (!Cookies.get(sessionCode)) {
            Cookies.set(sessionCode, 'seen', { expires: 3 });
        }
    }, []);
    function buildInitialSessionInfo() {
        return __awaiter(this, void 0, void 0, function () {
            var sessionInfo, hintsCount, hintPromises, i, loadedHints, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!userToken) {
                            return [2 /*return*/];
                        }
                        setLoadingNextHint(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 7, 8, 9]);
                        return [4 /*yield*/, getSessionInfo()];
                    case 2:
                        sessionInfo = _a.sent();
                        if (!(sessionInfo && sessionInfo.hintsCount > 0)) return [3 /*break*/, 4];
                        hintsCount = sessionInfo.hintsCount;
                        hintPromises = [];
                        for (i = 0; i < hintsCount; i++) {
                            hintPromises.push(ImageServer.generateImage(userToken, sessionCode, i));
                        }
                        if (sessionInfo.isCorrectlyGuessed) {
                            setValidationResult("Congrats with guessing this one already!");
                        }
                        return [4 /*yield*/, Promise.all(hintPromises)];
                    case 3:
                        loadedHints = _a.sent();
                        setImages(loadedHints);
                        return [3 /*break*/, 6];
                    case 4: return [4 /*yield*/, appendNewHint()];
                    case 5:
                        _a.sent();
                        _a.label = 6;
                    case 6: return [3 /*break*/, 9];
                    case 7:
                        error_1 = _a.sent();
                        console.error("Error loading initial hints:", error_1);
                        return [3 /*break*/, 9];
                    case 8:
                        setLoadingNextHint(false);
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    }
    function getSessionInfo() {
        return __awaiter(this, void 0, void 0, function () {
            var sessionInfos, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!userToken)
                            return [2 /*return*/, null];
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, SessionCacheRemote.getSessionsInfoForUser(userToken, [sessionCode])];
                    case 2:
                        sessionInfos = _a.sent();
                        return [2 /*return*/, sessionInfos.find(function (info) { return info.sessionCode === sessionCode; })];
                    case 3:
                        error_2 = _a.sent();
                        console.error("Error fetching session info:", error_2);
                        return [2 /*return*/, null];
                    case 4: return [2 /*return*/];
                }
            });
        });
    }
    function appendNewHint() {
        return __awaiter(this, void 0, void 0, function () {
            var generatedImages, _a, _b, error_3;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!userToken || loadingNextHint)
                            return [2 /*return*/];
                        setLoadingNextHint(true);
                        _c.label = 1;
                    case 1:
                        _c.trys.push([1, 3, 4, 5]);
                        generatedImages = __spreadArray([], images, true);
                        _b = (_a = generatedImages).push;
                        return [4 /*yield*/, ImageServer.generateImage(userToken, sessionCode, images.length)];
                    case 2:
                        _b.apply(_a, [_c.sent()]);
                        setImages(generatedImages);
                        return [3 /*break*/, 5];
                    case 3:
                        error_3 = _c.sent();
                        if (error_3.response && error_3.response.status == 429) {
                            setError("Not that fast! I'm still drawing new hints for you, come back later :)");
                        }
                        else {
                            console.error(error_3);
                            setIsValidSessionCode(false);
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        setLoadingNextHint(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    }
    function canGetMoreHints() {
        var requestedHints = images.length;
        if (loadingNextHint) {
            requestedHints++;
        }
        return requestedHints < MAX_NUMBER_OF_HINTS;
    }
    function showNextImage() {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                setError("");
                if (canGetMoreHints()) {
                    appendNewHint();
                }
                return [2 /*return*/];
            });
        });
    }
    var handleKeyPress = function (event) {
        if (event.key === "Enter") {
            handleSubmit();
        }
    };
    function handleSubmit() {
        return __awaiter(this, void 0, void 0, function () {
            var res, guessResult;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!userToken)
                            return [2 /*return*/];
                        res = WordValidator.basicValidation(playerGuess);
                        if (res.verdict === false) {
                            setValidationResult(res.errorText);
                            return [2 /*return*/];
                        }
                        return [4 /*yield*/, SessionCacheRemote.checkGuess(userToken, playerGuess, sessionCode)];
                    case 1:
                        guessResult = _a.sent();
                        if (guessResult === undefined) {
                            setError("Failed to check the guess. Please reload the page and try again.");
                        }
                        if (guessResult) {
                            setValidationResult("Correct!");
                            setGuessedCorrectly(true);
                            Cookies.set(sessionCode, 'done', { expires: 3 });
                        }
                        else {
                            setValidationResult("Wrong guess!");
                        }
                        return [2 /*return*/];
                }
            });
        });
    }
    var handleInputFieldChanged = function (event) {
        setPlayerGuess(event.target.value);
        setValidationResult("");
        setError("");
    };
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "App App-justify-top" }, { children: [guessedCorrectly && (_jsx(Fireworks, { options: {
                        rocketsPoint: {
                            min: 0,
                            max: 100
                        },
                    }, style: {
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        zIndex: "9999",
                        background: "transparent",
                        pointerEvents: "none"
                    } })), isValidSessionCode && (_jsxs(_Fragment, { children: [_jsx("p", __assign({ className: "title" }, { children: "AI made images using the word from another player..." })), _jsxs("div", __assign({ className: "hints-div" }, { children: [images.map(function (url, index) { return (_jsx("img", { className: "hint-image", src: url, alt: "".concat(index) }, index)); }), loadingNextHint && (_jsx("div", { className: "loading-hint-spinner" })), canGetMoreHints() && (_jsx("img", { className: "get-new-hint-image", onClick: showNextImage, src: require("../../data/get_new_hint.png").default, alt: "getNewHint" }))] })), error && (_jsx("p", __assign({ role: "alert" }, { children: error }))), _jsxs("div", __assign({ className: "guess-enter-div" }, { children: [_jsxs("div", __assign({ className: "guess-textbox-div" }, { children: [_jsx("input", { type: "text", name: "playerGuess", placeholder: "Enter your guess", className: "Text-box-underline Guess-text-box-position", onChange: handleInputFieldChanged, onKeyDown: handleKeyPress, autoComplete: "off", disabled: guessedCorrectly }), _jsx("img", { src: require("../../data/submit_button.png").default, className: "Submit-button", alt: "logo", onClick: handleSubmit })] })), validationResult && (_jsx("p", __assign({ role: "alert" }, { children: validationResult })))] }))] })), !isValidSessionCode && (_jsx("p", { children: "Invalid session code. Check your URL!" }))] })) }));
};
export default GuessRiddleScreen;
