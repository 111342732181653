var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import "./HelpScreen.css";
var HelpScreen = function () {
    return (_jsxs("div", __assign({ className: "information-screen" }, { children: [_jsxs("div", __assign({ className: "info-header" }, { children: [_jsx("img", { src: require("../../data/lamp.png").default, className: "information-logo", alt: "information logo" }), _jsx("h1", { children: "How to play" })] })), _jsx("p", {}), _jsx("p", { children: "Welcome to DynamoGuesser, a simple AI game! Your goal is to guess the hidden nouns in images created by AI." }), _jsxs("p", { children: ["Ready to dive in? ", _jsx(Link, __assign({ to: "/guess-a-riddle", className: "info-link" }, { children: "Explore existing riddles" })), "."] }), _jsxs("p", { children: ["Want something more? ", _jsx(Link, __assign({ to: "/enter-word", className: "info-link" }, { children: "Create a new riddle" })), " and challenge others."] }), _jsx("p", {}), _jsx("p", {}), _jsxs("p", { children: ["Contact us: ", _jsx(Link, __assign({ to: "mailto:support@dynamoguesser.com", className: "info-link" }, { children: "support@dynamoguesser.com" }))] }), _jsx("p", {}), _jsx("p", {}), _jsx(Link, __assign({ to: "/privacy-policy", className: "info-link" }, { children: "Privacy Policy" })), _jsx("p", {}), _jsx(Link, __assign({ to: "/account-deletion", className: "/info-link" }, { children: "Account deletion" }))] })));
};
export default HelpScreen;
