var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var ValidationResult = /** @class */ (function () {
    function ValidationResult(error, verdict) {
        this.verdict = false;
        this.errorText = "";
        if (verdict) {
            this.verdict = verdict;
        }
        if (error) {
            this.errorText = error;
        }
    }
    return ValidationResult;
}());
var WordValidator = /** @class */ (function () {
    function WordValidator() {
    }
    WordValidator.basicValidation = function (inputWord) {
        var word = this.cleanWord(inputWord);
        if (word === "") {
            return new ValidationResult("Empty field");
        }
        else if (word.split(' ').length > 1) {
            return new ValidationResult("Only a single word allowed!");
        }
        else if (word.length <= 2) {
            return new ValidationResult('The word is too short, try another one');
        }
        else {
            return new ValidationResult("", true);
        }
    };
    WordValidator.checkNounExists = function (candidate) {
        return __awaiter(this, void 0, void 0, function () {
            var url, response, data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        url = "https://api.datamuse.com/words?sp=".concat(candidate, "&max=1&md=p");
                        return [4 /*yield*/, fetch(url)];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        if (data.length === 0 || data[0]["word"] !== candidate) {
                            // doesn't exist
                            return [2 /*return*/, -1];
                        }
                        else if (!data[0]["tags"].includes("n")) {
                            // isn't a noun
                            return [2 /*return*/, 0];
                        }
                        else {
                            // ok
                            return [2 /*return*/, 1];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    WordValidator.dictionaryValidation = function (inputWord) {
        return __awaiter(this, void 0, void 0, function () {
            var res, word;
            return __generator(this, function (_a) {
                res = this.basicValidation(inputWord);
                if (res.verdict === false) {
                    return [2 /*return*/, res];
                }
                word = this.cleanWord(inputWord);
                return [2 /*return*/, this.checkNounExists(word).then(function (nounExists) {
                        if (nounExists === -1) {
                            return new ValidationResult("Whoops, this word doesn't seem to exist yet");
                        }
                        else if (!nounExists) {
                            return new ValidationResult("Doesn't look like a noun to me");
                        }
                        else {
                            return new ValidationResult("", true);
                        }
                    })];
            });
        });
    };
    WordValidator.cleanWord = function (word) { return word.trim().toLowerCase(); };
    return WordValidator;
}());
export { WordValidator };
