var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import Cookies from 'js-cookie';
import { SessionCacheRemote } from "../backend-api/server-api";
import "./common-styles.css";
import "./RiddlesListScreen.css";
var MAXIMUM_SESSIONS_TO_SHOW = 100;
var formatDate = function (date) {
    var formatter = new Intl.DateTimeFormat(undefined, { month: "short", day: "numeric", hour: "numeric", minute: "numeric" });
    return formatter.format(date);
};
var Table = function (_a) {
    var data = _a.data;
    return (_jsxs("table", { children: [_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "header-row-cell" }, { children: "Riddle" })), _jsx("th", __assign({ className: "header-row-cell" }, { children: "Author" })), _jsx("th", __assign({ className: "header-row-cell" }, { children: "State" })), _jsx("th", __assign({ className: "header-row-cell" }, { children: "Date" }))] }) }), _jsx("tbody", { children: data.map(function (session) { return (_jsxs("tr", { children: [_jsx("td", { children: session.sessionCode }), _jsx("td", __assign({ className: "creator-cell" }, { children: session.creator })), _jsx("td", { children: Cookies.get(session.sessionCode) === "visited" ? "seen" :
                                Cookies.get(session.sessionCode) === "guessed" ? "done" :
                                    Cookies.get(session.sessionCode) || "new" }), _jsx("td", { children: formatDate(new Date(session.creationTimestamp)) }), _jsx("td", __assign({ className: "join-button" }, { children: _jsx("img", { src: require("../../data/submit_button.png").default, className: "join-riddle-button", alt: "logo", onClick: function () {
                                    window.location.href = "/game/".concat(session.sessionCode);
                                } }) }))] }, session.sessionCode)); }) })] }));
};
var RiddlesListScreen = function () {
    var _a = useState([]), tableData = _a[0], setTableData = _a[1];
    useEffect(function () {
        SessionCacheRemote.listActiveSessions().then(function (sessions) {
            sessions = sessions.sort(function (a, b) {
                return new Date(b.creationTimestamp).getTime() - new Date(a.creationTimestamp).getTime();
            });
            setTableData(sessions.slice(0, MAXIMUM_SESSIONS_TO_SHOW));
        });
    }, []);
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "App" }, { children: [_jsx("p", { children: "Select a riddle to guess" }), _jsx("div", { children: _jsx(Table, { data: tableData }) })] })) }));
};
export default RiddlesListScreen;
