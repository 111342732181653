var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import "../button-styles.css";
import "./common-styles.css";
import { SessionCacheRemote } from '../backend-api/server-api';
import { getAuth, sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import firebaseApp from '../auth/firebase';
var RegisterScreen = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(''), username = _c[0], setUsername = _c[1];
    var _d = useState(null), errorMessage = _d[0], setErrorMessage = _d[1];
    var _e = useState(false), privacyPolicyAccepted = _e[0], setPrivacyPolicyAccepted = _e[1];
    var humanReadableError = function (firebaseError) {
        var errorMap = {
            "failed_to_decode": "Could not parse email, password or username.",
            "invalid_username": "Username must be between 4 and 15 characters and have only letters and digits.",
            "username_already_exists": "That username is already taken.",
            "duplicate_email": "The email address is already in use by another account.",
        };
        return errorMap[firebaseError] || firebaseError;
    };
    var isValidEmail = function (email) {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };
    var isValidPassword = function (password) {
        return password.length >= 8;
    };
    var register = function () { return __awaiter(void 0, void 0, void 0, function () {
        var err, errorMessage_1, auth, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!privacyPolicyAccepted) {
                        setErrorMessage("Privacy policy has to be accepted.");
                        return [2 /*return*/];
                    }
                    if (!isValidEmail(email)) {
                        setErrorMessage("The email address is badly formatted.");
                        return [2 /*return*/];
                    }
                    if (!isValidPassword(password)) {
                        setErrorMessage("The password should have at least 8 characters.");
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, SessionCacheRemote.registerUser(email, password, username)];
                case 2:
                    err = _a.sent();
                    if (!err) return [3 /*break*/, 3];
                    errorMessage_1 = humanReadableError(err.message);
                    setErrorMessage(errorMessage_1);
                    return [3 /*break*/, 7];
                case 3:
                    auth = getAuth(firebaseApp);
                    setErrorMessage(null);
                    return [4 /*yield*/, signInWithEmailAndPassword(auth, email, password)];
                case 4:
                    _a.sent();
                    if (!auth.currentUser) return [3 /*break*/, 6];
                    return [4 /*yield*/, sendEmailVerification(auth.currentUser)];
                case 5:
                    _a.sent();
                    _a.label = 6;
                case 6:
                    window.location.href = '/verify-email';
                    _a.label = 7;
                case 7: return [3 /*break*/, 9];
                case 8:
                    error_1 = _a.sent();
                    console.error("Registration error: ", error_1);
                    setErrorMessage("An error occurred while registering. Please try again.");
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var handleKeyPress = function (event) {
        if (event.key === "Enter") {
            register();
        }
    };
    var handlePrivacyPolicyChange = function (event) {
        setPrivacyPolicyAccepted(event.target.checked);
    };
    return (_jsxs("div", __assign({ className: "App-Login" }, { children: [_jsx("h2", { children: "User Registration" }), _jsxs("div", __assign({ className: "form-container" }, { children: [_jsx("input", { className: "input-field", onKeyDown: handleKeyPress, value: username, onChange: function (e) { return setUsername(e.target.value); }, placeholder: "Username" }), _jsx("input", { className: "input-field", onKeyDown: handleKeyPress, value: email, onChange: function (e) { return setEmail(e.target.value); }, placeholder: "Email" }), _jsx("input", { className: "input-field", onKeyDown: handleKeyPress, type: "password", value: password, onChange: function (e) { return setPassword(e.target.value); }, placeholder: "Password" }), _jsxs("div", __assign({ className: "checkbox-container" }, { children: [_jsx("input", { type: "checkbox", id: "privacyPolicyConsent", required: true, onChange: handlePrivacyPolicyChange }), _jsxs("label", __assign({ htmlFor: "privacyPolicyConsent" }, { children: ["I agree to the ", _jsx("a", __assign({ href: "/privacy-policy", className: "privacy-policy-link", target: "_blank" }, { children: "Privacy Policy" })), " and acknowledge that I will be interacting with OpenAI generated content and my inputs might be sent over the OpenAI API."] }))] }))] })), _jsxs("div", __assign({ className: "buttons-container" }, { children: [_jsx("button", __assign({ className: "small-button Styled-button", onClick: register }, { children: "Register" })), _jsx("button", __assign({ className: "small-button Styled-button", onClick: function () { return window.location.href = '/'; } }, { children: "Back" }))] })), errorMessage && _jsx("div", __assign({ className: "error-message" }, { children: errorMessage })), _jsx("div", __assign({ className: "footer-links" }, { children: _jsx("a", __assign({ href: "/login" }, { children: "Already have an account? Login" })) }))] })));
};
export default RegisterScreen;
