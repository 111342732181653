// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    min-height: 93vh;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #282c34;
    width: 100%;
    box-sizing: border-box;
}

.privacy-policy-screen p, .privacy-policy-screen ul {
    margin: 1rem 0;
    font-size: calc(10px + 2vmin);
    max-width: 800px;
    text-align: justify;
}

.privacy-policy-screen ul {
    padding-left: 20px; /* Adjust as needed for proper indentation */
    list-style-type: disc; /* Choose the style of bullet you prefer */
}

.privacy-policy-screen li {
    margin-bottom: 0.5rem; /* Space between list items */
}

.privacy-policy-screen a {
    color: #FF8929;
    text-decoration: none;
    transition: color 0.3s ease;
}

.privacy-policy-screen a:hover {
    text-decoration: underline;
    color: #f3d6bc;
}
`, "",{"version":3,"sources":["webpack://./src/help-screens/PrivacyPolicyScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,gBAAgB;IAChB,6BAA6B;IAC7B,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,cAAc;IACd,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB,EAAE,4CAA4C;IAChE,qBAAqB,EAAE,0CAA0C;AACrE;;AAEA;IACI,qBAAqB,EAAE,6BAA6B;AACxD;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,cAAc;AAClB","sourcesContent":[".privacy-policy-screen {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    padding: 2rem;\n    min-height: 93vh;\n    font-size: calc(10px + 2vmin);\n    color: white;\n    background-color: #282c34;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.privacy-policy-screen p, .privacy-policy-screen ul {\n    margin: 1rem 0;\n    font-size: calc(10px + 2vmin);\n    max-width: 800px;\n    text-align: justify;\n}\n\n.privacy-policy-screen ul {\n    padding-left: 20px; /* Adjust as needed for proper indentation */\n    list-style-type: disc; /* Choose the style of bullet you prefer */\n}\n\n.privacy-policy-screen li {\n    margin-bottom: 0.5rem; /* Space between list items */\n}\n\n.privacy-policy-screen a {\n    color: #FF8929;\n    text-decoration: none;\n    transition: color 0.3s ease;\n}\n\n.privacy-policy-screen a:hover {\n    text-decoration: underline;\n    color: #f3d6bc;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
