// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  max-width: 1500px;
  border-collapse: collapse;
  font-size: 26px;
}

.header-row-cell {
  background-color: #e67222;
  padding-left: 1.5vw;
  color: white;
}

th,
td {
  border: 0px solid #e67222;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
  text-align: left;
}

.join-riddle-button {
  padding-right: 3vw;
  height: 6vmin;
}

.join-button {
  border: none;
}

.join-riddle-button:hover {
  filter: brightness(120%);
}

@media screen and (max-width: 900px) {
  th, td {
    font-size: 22px;
  }
}

@media screen and (max-width: 700px) {
  th, td {
    font-size: 16px;
  }

  .creator-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/game-screens/RiddlesListScreen.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;EACjB,yBAAyB;EACzB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,YAAY;AACd;;AAEA;;EAEE,yBAAyB;EACzB,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,eAAe;EACjB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;EACjB;AACF","sourcesContent":["table {\n  width: 100%;\n  max-width: 1500px;\n  border-collapse: collapse;\n  font-size: 26px;\n}\n\n.header-row-cell {\n  background-color: #e67222;\n  padding-left: 1.5vw;\n  color: white;\n}\n\nth,\ntd {\n  border: 0px solid #e67222;\n  padding-left: 1.5vw;\n  padding-right: 1.5vw;\n  text-align: left;\n}\n\n.join-riddle-button {\n  padding-right: 3vw;\n  height: 6vmin;\n}\n\n.join-button {\n  border: none;\n}\n\n.join-riddle-button:hover {\n  filter: brightness(120%);\n}\n\n@media screen and (max-width: 900px) {\n  th, td {\n    font-size: 22px;\n  }\n}\n\n@media screen and (max-width: 700px) {\n  th, td {\n    font-size: 16px;\n  }\n\n  .creator-cell {\n    white-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    max-width: 80px;\n  }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
