var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../game-screens/common-styles.css";
import "../button-styles.css";
import { SessionCacheRemote } from "../backend-api/server-api";
var isAdminUser = function (currentUser) {
    return currentUser && (currentUser.displayName == "awa149" || currentUser.displayName == "tutuka");
};
var AddDailyChallengeScreen = function (_a) {
    var userToken = _a.userToken, currentUser = _a.currentUser, logout = _a.logout;
    var _b = useState(""), date = _b[0], setDate = _b[1];
    var _c = useState(""), words = _c[0], setWords = _c[1];
    var _d = useState(false), overwrite = _d[0], setOverwrite = _d[1];
    var _e = useState("? challenges ready"), readyChallengesStr = _e[0], setReadyChallengesStr = _e[1];
    var _f = useState(""), error = _f[0], setError = _f[1];
    var navigate = useNavigate();
    if (!isAdminUser(currentUser)) {
        return _jsx("div", { children: "Access Denied." });
    }
    function updateReadyChallengesCnt() {
        return __awaiter(this, void 0, void 0, function () {
            var readyChallenges;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, SessionCacheRemote.readyChallengesCount()];
                    case 1:
                        readyChallenges = _a.sent();
                        setReadyChallengesStr(readyChallenges + " challenges ready");
                        return [2 /*return*/];
                }
            });
        });
    }
    var handleSubmit = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var splitWords, start, promises, i, word, currentDate, formattedDate;
        return __generator(this, function (_a) {
            e.preventDefault();
            if (!userToken) {
                setError("Unathorized.");
                return [2 /*return*/];
            }
            if (!date || !words) {
                setError("Both date and words are required.");
                return [2 /*return*/];
            }
            splitWords = words.split(',');
            start = new Date(date + 'Z');
            promises = [];
            for (i = 0; i < splitWords.length; i++) {
                word = splitWords[i];
                currentDate = new Date(start);
                currentDate.setUTCDate(currentDate.getUTCDate() + i);
                formattedDate = currentDate.toISOString().split('T')[0];
                promises.push(SessionCacheRemote.addDailyChallenge(formattedDate, word, overwrite.toString(), userToken));
            }
            Promise.all(promises).then(function (results) {
                setError("Success: " + results.join(','));
            });
            return [2 /*return*/];
        });
    }); };
    return (_jsxs("div", __assign({ className: "App" }, { children: [(!isAdminUser(currentUser)) && _jsx("div", { children: "Access Denied." }), _jsx("h2", { children: "Add New Daily Challenge" }), _jsxs("label", { children: ["Date (YYYY-MM-DD):", _jsx("input", { type: "text", value: date, onChange: function (e) { return setDate(e.target.value); }, placeholder: "YYYY-MM-DD" })] }), _jsx("br", {}), _jsxs("label", { children: ["Words (comma-separated):", _jsx("input", { type: "text", value: words, onChange: function (e) { return setWords(e.target.value); }, placeholder: "word1,word2,word3" })] }), _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: overwrite, onChange: function (e) { return setOverwrite(e.target.checked); } }), "overwrite"] }), _jsx("br", {}), _jsx("button", __assign({ type: "submit", className: "Styled-button", onClick: handleSubmit }, { children: "Submit" })), _jsx("p", {}), _jsxs("label", { children: [readyChallengesStr + "   ", _jsx("button", __assign({ className: "Styled-button", onClick: updateReadyChallengesCnt }, { children: "update" }))] }), error && _jsx("p", __assign({ role: "alert" }, { children: error }))] })));
};
export default AddDailyChallengeScreen;
