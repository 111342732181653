// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 3vmin);
    color: white;
    background-color: #282c34;
    min-height: 93vh;
}

.Text-box-underline {
    height: 4vmin;
    font-size: 4vmin;
    margin-top: 3vmin;
    border-bottom-left-radius: 2vmin;
    border-bottom-right-radius: 1vmin;
    border: 0;
    outline: none;
    color: white;
    background-color: transparent;
    border-bottom: 0.5vmin solid #ddc0ad;
    padding: 0.3vmin;
    padding-left: 3vmin;
    font-family: 'Courier New', Courier, monospace;
}
`, "",{"version":3,"sources":["webpack://./src/game-screens/common-styles.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,6BAA6B;IAC7B,YAAY;IACZ,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,iBAAiB;IACjB,gCAAgC;IAChC,iCAAiC;IACjC,SAAS;IACT,aAAa;IACb,YAAY;IACZ,6BAA6B;IAC7B,oCAAoC;IACpC,gBAAgB;IAChB,mBAAmB;IACnB,8CAA8C;AAClD","sourcesContent":[".App {\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    font-size: calc(10px + 3vmin);\n    color: white;\n    background-color: #282c34;\n    min-height: 93vh;\n}\n\n.Text-box-underline {\n    height: 4vmin;\n    font-size: 4vmin;\n    margin-top: 3vmin;\n    border-bottom-left-radius: 2vmin;\n    border-bottom-right-radius: 1vmin;\n    border: 0;\n    outline: none;\n    color: white;\n    background-color: transparent;\n    border-bottom: 0.5vmin solid #ddc0ad;\n    padding: 0.3vmin;\n    padding-left: 3vmin;\n    font-family: 'Courier New', Courier, monospace;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
