var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFirebaseAuth from "./useFirebaseAuth";
var AuthenticationGuard = function (_a) {
    var children = _a.children;
    var _b = useFirebaseAuth(), currentUser = _b.currentUser, userToken = _b.userToken, logout = _b.logout;
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    useEffect(function () {
        if (userToken === null) {
            navigate('/login');
        }
        else if (userToken) {
            setLoading(false);
            if (!(currentUser === null || currentUser === void 0 ? void 0 : currentUser.emailVerified)) {
                navigate('/verify-email');
            }
        }
    }, [userToken, navigate]);
    // If userToken is being loaded (e.g., during initial authentication check), show a loading indicator
    if (loading) {
        return (_jsx("div", __assign({ style: { color: 'white', backgroundColor: '#282c34', justifyContent: 'center', height: '100vh', } }, { children: "Page loading..." })));
    }
    // If userToken is available, render the protected component, passing userToken as property
    //return <>{children}</>;
    return React.cloneElement(children, { userToken: userToken, currentUser: currentUser, logout: logout });
};
export default AuthenticationGuard;
