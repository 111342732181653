// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Lets-go-button {
  margin-top: 5vmin;
}

.spinner {
  border: 0.5vmin solid #f3f3f3;
  border-top: 0.5vmin solid #FF8929;
  border-radius: 50%;
  margin-top: 10vmin;
  width: 7vmin;
  height: 7vmin;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}`, "",{"version":3,"sources":["webpack://./src/game-screens/PlayerInputScreen.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,iCAAiC;EACjC,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,aAAa;EACb,kCAAkC;AACpC;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC","sourcesContent":[".Lets-go-button {\n  margin-top: 5vmin;\n}\n\n.spinner {\n  border: 0.5vmin solid #f3f3f3;\n  border-top: 0.5vmin solid #FF8929;\n  border-radius: 50%;\n  margin-top: 10vmin;\n  width: 7vmin;\n  height: 7vmin;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
