// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Styled-button {
    text-decoration: none;
    display: inline-block;
    border-radius: 2vmin;
    background-color: #FF8929;
    background-image: linear-gradient(to top, #FF8929,  #f3d6bc);
    background-size: 100% 8%;
    background-repeat: no-repeat;
    border: none;
    color: #fff;
    text-align: center;
    font-size: 4vmin;
    padding: 2vmin 9vmin;
    transition: all 0.3s ease;
    cursor: pointer;
  }
  
  .Styled-button:hover {
    filter: brightness(120%);
  }`, "",{"version":3,"sources":["webpack://./src/button-styles.css"],"names":[],"mappings":";AACA;IACI,qBAAqB;IACrB,qBAAqB;IACrB,oBAAoB;IACpB,yBAAyB;IACzB,4DAA4D;IAC5D,wBAAwB;IACxB,4BAA4B;IAC5B,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,gBAAgB;IAChB,oBAAoB;IACpB,yBAAyB;IACzB,eAAe;EACjB;;EAEA;IACE,wBAAwB;EAC1B","sourcesContent":["\n.Styled-button {\n    text-decoration: none;\n    display: inline-block;\n    border-radius: 2vmin;\n    background-color: #FF8929;\n    background-image: linear-gradient(to top, #FF8929,  #f3d6bc);\n    background-size: 100% 8%;\n    background-repeat: no-repeat;\n    border: none;\n    color: #fff;\n    text-align: center;\n    font-size: 4vmin;\n    padding: 2vmin 9vmin;\n    transition: all 0.3s ease;\n    cursor: pointer;\n  }\n  \n  .Styled-button:hover {\n    filter: brightness(120%);\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
