// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.information-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 2rem;
    min-height: 93vh;
    font-size: calc(10px + 2vmin);
    color: white;
    background-color: #282c34;
    width: 100%;
    box-sizing: border-box;
}

.info-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.information-logo {
    height: 14vmin;
    pointer-events: none;
    margin-right: 2vmin;
}

.information-screen h1 {
    margin: 2rem;
}

.information-screen p, ol {
    margin: 1rem 0;
    font-size: calc(10px + 2vmin);
    max-width: 800px;
    text-align: justify;
}

.information-screen a {
    color: #FF8929;
    text-decoration: none;
    transition: color 0.3s ease;
}

.information-screen a:hover {
    text-decoration: underline;
    color: #f3d6bc;
}`, "",{"version":3,"sources":["webpack://./src/help-screens/HelpScreen.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,2BAA2B;IAC3B,aAAa;IACb,gBAAgB;IAChB,6BAA6B;IAC7B,YAAY;IACZ,yBAAyB;IACzB,WAAW;IACX,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,WAAW;AACf;;AAEA;IACI,cAAc;IACd,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,cAAc;IACd,6BAA6B;IAC7B,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,qBAAqB;IACrB,2BAA2B;AAC/B;;AAEA;IACI,0BAA0B;IAC1B,cAAc;AAClB","sourcesContent":[".information-screen {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: flex-start;\n    padding: 2rem;\n    min-height: 93vh;\n    font-size: calc(10px + 2vmin);\n    color: white;\n    background-color: #282c34;\n    width: 100%;\n    box-sizing: border-box;\n}\n\n.info-header {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n}\n\n.information-logo {\n    height: 14vmin;\n    pointer-events: none;\n    margin-right: 2vmin;\n}\n\n.information-screen h1 {\n    margin: 2rem;\n}\n\n.information-screen p, ol {\n    margin: 1rem 0;\n    font-size: calc(10px + 2vmin);\n    max-width: 800px;\n    text-align: justify;\n}\n\n.information-screen a {\n    color: #FF8929;\n    text-decoration: none;\n    transition: color 0.3s ease;\n}\n\n.information-screen a:hover {\n    text-decoration: underline;\n    color: #f3d6bc;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
