import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import "../data/favicon.ico";
import "../public/logo192.png";
import RegisterScreen from './auth-screens/RegisterScreen';
import LoginScreen from './auth-screens/LoginScreen';
import ForgottenPasswordScreen from './auth-screens/ForgottenPasswordScreen';
import HomeScreen from './game-screens/HomeScreen';
import PlayerInputScreen from './game-screens/PlayerInputScreen';
import RiddlesListScreen from './game-screens/RiddlesListScreen';
import GuessRiddleScreen from './game-screens/GuessRiddleScreen';
import AppHeader from './AppHeader';
import AuthenticationGuard from './auth/AuthenticationGuard';
import HelpScreen from './help-screens/HelpScreen';
import PrivacyPolicyScreen from './help-screens/PrivacyPolicyScreen';
import AddDailyChallengeScreen from './admin-screens/AddDailyChallengeScreen';
import DailyChallengeLoader from './game-screens/DailyChallengeLoader';
import AccountDeletionScreen from './help-screens/AccountDeletionScreen';
import VerifyEmailScreen from './auth-screens/VerifyEmailScreen';
function AppContent() {
    var location = useLocation();
    var excludedPaths = [];
    var showHeader = !excludedPaths.includes(location.pathname);
    return (_jsxs(_Fragment, { children: [showHeader && _jsx(AppHeader, {}), _jsxs(Routes, { children: [_jsx(Route, { path: "/", element: _jsx(HomeScreen, {}) }), _jsx(Route, { path: "/enter-word", element: _jsx(AuthenticationGuard, { children: _jsx(PlayerInputScreen, {}) }) }), _jsx(Route, { path: "/game/:sessionCodeParam", element: _jsx(AuthenticationGuard, { children: _jsx(GuessRiddleScreen, {}) }) }), _jsx(Route, { path: "/guess-a-riddle", element: _jsx(AuthenticationGuard, { children: _jsx(RiddlesListScreen, {}) }) }), _jsx(Route, { path: "/register", element: _jsx(RegisterScreen, {}) }), _jsx(Route, { path: "/login", element: _jsx(LoginScreen, {}) }), _jsx(Route, { path: "/forgotten-password", element: _jsx(ForgottenPasswordScreen, {}) }), _jsx(Route, { path: "/verify-email", element: _jsx(VerifyEmailScreen, {}) }), _jsx(Route, { path: "/daily-challenge", element: _jsx(DailyChallengeLoader, {}) }), _jsx(Route, { path: "/help", element: _jsx(HelpScreen, {}) }), _jsx(Route, { path: "/account-deletion", element: _jsx(AccountDeletionScreen, {}) }), _jsx(Route, { path: "/privacy-policy", element: _jsx(PrivacyPolicyScreen, {}) }), _jsx(Route, { path: "/add-daily-challenge", element: _jsx(AuthenticationGuard, { children: _jsx(AddDailyChallengeScreen, {}) }) }), _jsx(Route, { path: "*", element: _jsx(Navigate, { to: "/", replace: true }) })] })] }));
}
function App() {
    return (_jsx(Router, { children: _jsx(AppContent, {}) }));
}
export default App;
